.calendarContainer .rbcCalendar {
  /* Your default styles here */
  height: 1000px;
  margin: 10px;
}

.confirmModalBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px; /* Corrected width with unit */
  background-color: #fff; /* Assuming 'background.paper' is white */
  border: 2px solid #000;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.15); /* Based on boxShadow 24 */
  padding: 32px; /* Single padding property */
}

.formControl {
  width: 320px;
  margin: 10px;
  /* Other default styles */
}

.modalBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 900px; /* Default width */
  padding: 32px;
  background-color: #fff; /* Assuming 'background.paper' is white */
  border: 2px solid #000;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.15); /* Based on boxShadow 24 */
  max-height: 90vh; /* Maximum height as a percentage of the viewport height */
  overflow-y: auto; /* Enables vertical scrolling if content overflows */
}

/* Styles for mobile devices */
@media (max-width: 768px) {
  .calendarContainer .rbcCalendar {
    /* Adjust styles for tablet screens */
    width: 100%; /* Take up full width */
    height: 400px; /* Adjust height for mobile view */
    margin: 5px;
  }

  .formControl {
    width: 100%;
    margin: 5px;
  }

  .modalBox {
    width: 90%; /* Width for smaller screens */
    padding: 10px;
    /* Adjust other styles for mobile responsiveness */
  }
}

/* Styles for mobile phones */
@media screen and (max-width: 480px) {
  .calendarContainer .rbcCalendar {
    /* Adjust styles for mobile screens */
    width: 100%; /* Take up full width */
    height: 400px; /* Adjust height for mobile view */
    margin: 5px;
  }
}

.customTabSelected {
  background-color: #003366 !important; /* Darker blue */
  color: white !important;
}

.customTabRoot {
  text-transform: none;
  min-width: 172px;
  margin-top: -5px;
  padding: 0;
  justify-content: center;
  padding-left: 10px;
  background-color: grey;
}
